export const statusTrainOptions = [
  { label: 'HOLD', value: 'HOLD', color: 'info' },
  { label: 'PAID', value: 'PAID', color: 'success' },
  { label: 'PROCESSING', value: 'PROCESSING', color: 'warning' },
  { label: 'CANCEL', value: 'CANCEL', color: 'danger' },
  { label: 'EXPIRED', value: 'EXPIRED', color: 'secondary' },
]

export const STATUS_TRAIN = statusTrainOptions.reduce((a, c) => ({ ...a, [c.value]: c.label }), {})
export default {}
