var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    attrs: {
      "id": "modal-locked-list",
      "title": _vm.$t('reservation.lockedList.title'),
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "centered": "",
      "ok-variant": "primary",
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "size": "lg",
      "no-enforce-focus": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var cancel = _ref.cancel;
        return [_c('b-button', {
          staticClass: "center rounded-pill",
          attrs: {
            "size": "md",
            "variant": "secondary"
          },
          on: {
            "click": function click($event) {
              return cancel();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.close')) + " ")])];
      }
    }])
  }, [_c('b-table', {
    staticClass: "position-relative",
    attrs: {
      "items": _vm.lockedList,
      "responsive": "",
      "fields": _vm.tableColumns,
      "primary-key": "id",
      "show-empty": "",
      "empty-text": _vm.$t('noMatchingResult'),
      "no-border-collapse": "",
      "small": ""
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumns, function (column) {
      return {
        key: "head(".concat(column, ")"),
        fn: function fn() {
          return [_c('span', {
            key: column.label,
            staticClass: "text-dark text-nowrap"
          }, [_vm._v(" " + _vm._s(_vm.$t("reservation.lockedList.columns.".concat(column))) + " ")])];
        },
        proxy: true
      };
    }), {
      key: "cell(no)",
      fn: function fn(data) {
        return [_c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(_vm._s(data.index + 1))])];
      }
    }, {
      key: "cell(bookingCode)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('b-link', {
          staticClass: "font-weight-bold",
          attrs: {
            "to": {
              name: 'apps-reservations-modify',
              params: {
                id: item.id
              }
            },
            "target": "_blank"
          }
        }, [_vm._v(" " + _vm._s(item.bookingCode) + " ")])];
      }
    }, {
      key: "cell(agPayment)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('span', {
          staticClass: "font-weight-bold text-uppercase"
        }, [_vm._v(_vm._s(item.agPayment))])];
      }
    }, {
      key: "cell(lockBalanceItem)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(_vm._s(_vm.formatCurrency(item.lockBalanceItem)))])];
      }
    }], null, true)
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }