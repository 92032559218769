export const ERROR_NOTI_APP_STORE_MODULE_NAME = 'app-error-noti'

// NOTE: BE not implement yet
export const DATE_OPTION_VALUES = {
  creationDate: 'createdAt',
  issueDate: 'issueDate',
}

export const DATE_OPTIONS = [
  {
    value: DATE_OPTION_VALUES.creationDate,
    label: 'errorNoti.dateOptions.creationDate',
  },
  {
    value: DATE_OPTION_VALUES.issueDate,
    label: 'errorNoti.dateOptions.issueDate',
  },
]

export const ERROR_NOTI_TYPE_OPTIONS = [
  {
    value: 'NNT',
    label: 'errorNoti.type.nnt',
  },
  {
    value: 'CQT',
    label: 'errorNoti.type.cqt',
  },
]

// TODO: BE thừa status 'ERROR_TO_ISSUE' ? => need check again
// DRAFT, WATTING_TO_SIGN, SIGN, REFUSE_TO_SIGN, WAITING_TO_ISSUE, ISSUE, ERROR_TO_ISSUE, REFUSE_TO_ISSUE

export const ERROR_NOTI_STATUS_VALUES = {
  draft: 'DRAFT', // trạng thái NHÁP
  waitingToSign: 'WATTING_TO_SIGN', // trạng thái CHỜ KÝ
  sign: 'SIGN', // trạng thái ĐÃ KÝ
  refuseToSign: 'REFUSE_TO_SIGN', // trạng thái bị TỪ CHỐI KÝ
  waitingToIssue: 'WAITING_TO_ISSUE', // trạng thái CHỜ CQT TIẾP NHẬN
  issue: 'ISSUE', // trạng thái CQT ĐÃ TIẾP NHẬN
  refuseToIssue: 'REFUSE_TO_ISSUE', // trạng thái bị CQT TỪ CHỐI
}

export const ERROR_NOTI_STATUS_OPTIONS = [
  {
    value: ERROR_NOTI_STATUS_VALUES.draft,
    label: 'errorNoti.status.draft',
  },
  {
    value: ERROR_NOTI_STATUS_VALUES.waitingToSign,
    label: 'errorNoti.status.waitingToSign',
  },
  {
    value: ERROR_NOTI_STATUS_VALUES.sign,
    label: 'errorNoti.status.sign',
  },
  {
    value: ERROR_NOTI_STATUS_VALUES.refuseToSign,
    label: 'errorNoti.status.refuseToSign',
  },
  {
    value: ERROR_NOTI_STATUS_VALUES.waitingToIssue,
    label: 'errorNoti.status.waitingToIssue',
  },
  {
    value: ERROR_NOTI_STATUS_VALUES.issue,
    label: 'errorNoti.status.issue',
  },
  {
    value: ERROR_NOTI_STATUS_VALUES.refuseToIssue,
    label: 'errorNoti.status.refuseToIssue',
  },
]

export const ERROR_NOT_TABLE_COLUMNS = [
  { label: 'checkbox', key: 'checkbox' },
  { label: 'no', key: 'no' },
  { label: 'type', key: 'type' },
  { label: 'invoices', key: 'invoices' },
  { label: 'taAcceptNo', key: 'taAcceptNo' },
  { label: 'taAcceptDate', key: 'taAcceptDate' },
  { label: 'status', key: 'status' },
  { label: 'created', key: 'createdAt', sortable: true },
  { label: 'updated', key: 'updatedAt', sortable: true },
  { label: 'actions', key: 'actions' },
]
