var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-menu menu-fixed menu-accordion menu-shadow",
    class: [{
      'expanded': !_vm.isVerticalMenuCollapsed || _vm.isVerticalMenuCollapsed && _vm.isMouseHovered
    }, _vm.skin === 'semi-dark' ? 'menu-dark' : 'menu-light'],
    on: {
      "mouseenter": function mouseenter($event) {
        return _vm.updateMouseHovered(true);
      },
      "mouseleave": function mouseleave($event) {
        return _vm.updateMouseHovered(false);
      }
    }
  }, [_c('div', {
    staticClass: "navbar-header expanded mb-0",
    staticStyle: {
      "height": "8rem"
    }
  }, [_c('div', {
    staticClass: "position-absolute position-top-0 position-right-0"
  }, [_c('b-link', {
    staticClass: "nav-link modern-nav-toggle"
  }, [_c('feather-icon', {
    staticClass: "d-block d-xl-none",
    attrs: {
      "icon": "XIcon",
      "size": "20"
    },
    on: {
      "click": _vm.toggleVerticalMenuActive
    }
  }), _c('feather-icon', {
    staticClass: "d-none d-xl-block collapse-toggle-icon",
    staticStyle: {
      "color": "#2B3378",
      "stroke-width": "2.5"
    },
    attrs: {
      "icon": "MenuIcon",
      "size": "20"
    },
    on: {
      "click": _vm.toggleCollapsed
    }
  })], 1)], 1), _vm._t("header", [_c('ul', {
    staticClass: "nav navbar-nav flex-row h-100",
    class: {
      'd-flex-center': !(!_vm.isMouseHovered && _vm.isVerticalMenuCollapsed && !_vm.$store.state.verticalMenu.isShowText)
    }
  }, [_c('li', {
    staticClass: "nav-item m-0 d-flex align-items-center"
  }, [_c('b-link', {
    staticClass: "navbar-brand",
    attrs: {
      "to": "/"
    }
  }, [_c('div', {
    staticClass: "position-relative text-title text-uppercase fw-700"
  }, [!_vm.isMouseHovered && _vm.isVerticalMenuCollapsed && !_vm.$store.state.verticalMenu.isShowText ? _c('span', {
    staticClass: "brand-logo"
  }, [_c('b-img', {
    staticStyle: {
      "transform": "translate(-10px)"
    },
    attrs: {
      "src": _vm.appLogoImage,
      "alt": "logo"
    }
  }), _vm.getEnv ? _c('div', {
    staticClass: "position-absolute text-subtitle",
    staticStyle: {
      "bottom": "-15px",
      "right": "0px",
      "color": "#2B3378"
    }
  }, [_vm._v(" " + _vm._s(_vm.getEnv) + " ")]) : _vm._e()], 1) : _c('span', [_c('b-img', {
    staticClass: "mt-0",
    staticStyle: {
      "max-height": "5.5rem",
      "max-width": "100%"
    },
    attrs: {
      "src": _vm.appLogoFull,
      "alt": "logo"
    }
  }), _vm.getEnv ? _c('div', {
    staticClass: "position-absolute",
    staticStyle: {
      "bottom": "-15px",
      "right": "-5px",
      "color": "#2B3378"
    }
  }, [_vm._v(_vm._s(_vm.getEnv))]) : _vm._e()], 1)])])], 1)])], {
    "toggleVerticalMenuActive": _vm.toggleVerticalMenuActive,
    "toggleCollapsed": _vm.toggleCollapsed,
    "collapseTogglerIcon": _vm.collapseTogglerIcon
  })], 2), _c('div', {
    staticClass: "shadow-bottom",
    class: {
      'd-block': _vm.shallShadowBottom
    }
  }), _c('vue-perfect-scrollbar', {
    staticClass: "main-menu-content scroll-area",
    attrs: {
      "settings": _vm.perfectScrollbarSettings,
      "tagname": "ul"
    },
    on: {
      "ps-scroll-y": function psScrollY(evt) {
        _vm.shallShadowBottom = evt.srcElement.scrollTop > 0;
      }
    }
  }, [_c('vertical-nav-menu-items', {
    staticClass: "navigation navigation-main",
    attrs: {
      "items": _vm.navMenuItems
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }