var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-form-checkbox', {
    class: _vm.customClass,
    attrs: {
      "checked": _vm.checked,
      "name": _vm.name,
      "switch": "",
      "inline": "",
      "disabled": _vm.disabled
    },
    nativeOn: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.changeActive($event);
      }
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }