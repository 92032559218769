var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('BAlert', {
    staticClass: "px-1 py-25 mb-25",
    staticStyle: {
      "font-size": "16px",
      "text-align": "justify",
      "color": "#00558F !important"
    },
    attrs: {
      "show": _vm.show && _vm.alertList && _vm.alertList.length > 0,
      "variant": "info"
    }
  }, [_c('div', {
    staticClass: "d-flex-center justify-content-between"
  }, [_c('strong', {
    staticClass: "text-info fw-700 d-flex-center text-uppercase",
    staticStyle: {
      "font-size": "18px",
      "text-align": "justify",
      "color": "#00558F !important"
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "size": "22",
      "icon": "AlertCircleIcon"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('important')) + " ")], 1), _c('div', {
    staticClass: "d-flex-center gap-1"
  }, [_c('b-button', {
    staticClass: "p-50",
    attrs: {
      "variant": "flat-info"
    },
    on: {
      "click": _vm.toggleCollapse
    }
  }, [_vm.isOpen ? _c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": "ChevronDownIcon",
      "size": "20"
    }
  }) : _c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": "ChevronUpIcon",
      "size": "20"
    }
  })], 1), _c('b-button', {
    staticClass: "p-50",
    attrs: {
      "variant": "flat-danger"
    },
    on: {
      "click": _vm.hide
    }
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": "XIcon",
      "size": "20"
    }
  })], 1)], 1)]), _c('div', {
    class: 'alert-container ' + (_vm.isOpen ? 'show' : 'hide')
  }, [_c('hr', {
    staticClass: "my-25"
  }), _c('div', {
    staticClass: "d-flex justify-content-between align-items-stretch gap-1"
  }, [_c('div', _vm._l(_vm.isMoreNotify ? _vm.alertListFull : _vm.alertList, function (alertItem, alertIndex) {
    return _c('strong', {
      key: alertIndex,
      staticClass: "d-flex-center justify-content-start gap-1",
      staticStyle: {
        "line-height": "1.5"
      }
    }, [alertItem.id ? _c('span', [_vm._v("⚠️")]) : _vm._e(), _c(alertItem.id ? 'b-link' : 'div', {
      tag: "component",
      staticClass: "text-break",
      class: {
        'hover-underline': !!alertItem.id,
        'text-danger': alertItem.priority === 'HIGH'
      },
      staticStyle: {
        "font-size": "16px",
        "text-align": "justify",
        "color": "#00558F"
      },
      attrs: {
        "to": {
          name: 'apps-documents-edit',
          params: {
            id: alertItem.id
          }
        }
      }
    }, [_c('span', {
      domProps: {
        "innerHTML": _vm._s("".concat(alertItem.key, " ").concat(alertItem.value[0]))
      }
    })]), _vm._l(alertItem.value.slice(1), function (valueItem, valueIndex) {
      return _c('div', {
        key: valueIndex,
        staticClass: "ml-1 text-justify"
      }, [_vm._v(" - "), _c('span', {
        domProps: {
          "innerHTML": _vm._s(valueItem)
        }
      })]);
    })], 2);
  }), 0), _vm.haveMoreNotify ? _c('div', {
    staticClass: "d-flex align-items-end"
  }, [_c('b-button', {
    staticClass: "p-50 cursor-pointer",
    attrs: {
      "variant": "flat-info"
    },
    on: {
      "click": _vm.toggleMoreNotify
    }
  }, [_vm.isMoreNotify ? _c('feather-icon', {
    attrs: {
      "icon": "ChevronDownIcon",
      "size": "20"
    }
  }) : _c('feather-icon', {
    attrs: {
      "icon": "ChevronUpIcon",
      "size": "20"
    }
  })], 1)], 1) : _vm._e()])])]), _c('BAlert', {
    staticClass: "px-1 py-25 mb-25 d-flex-between",
    staticStyle: {
      "font-size": "16px",
      "text-align": "justify"
    },
    attrs: {
      "show": _vm.expiredPackageList.length > 0 && _vm.isOpenExpiredPackage,
      "variant": "warning"
    }
  }, [_c('div', _vm._l(_vm.expiredPackageList, function (expiredPackage) {
    return _c('div', {
      key: expiredPackage.id,
      class: "".concat(expiredPackage.countDayBeforeExpired < 0 ? 'text-danger' : '', " my-25")
    }, [_vm._v(" Gói bán lẻ " + _vm._s(expiredPackage.packageConfig.name) + " hết hạn vào " + _vm._s(_vm.convertISODateTime(expiredPackage.expiredAt).date) + ", "), expiredPackage.countDayBeforeExpired >= 0 ? _c('span', [_vm._v(" còn " + _vm._s(expiredPackage.countDayBeforeExpired) + " ngày ")]) : _c('span', [_vm._v(" đã hết hạn " + _vm._s(-expiredPackage.countDayBeforeExpired) + " ngày trước ")])]);
  }), 0), _c('div', {
    staticClass: "d-flex gap-2"
  }, [_c('b-button', {
    staticClass: "p-50",
    attrs: {
      "variant": "outline-warning"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'apps-agencyPackageConfigs-list'
        });
      }
    }
  }, [_vm._v(" Xem ngay ")]), _c('b-button', {
    staticClass: "p-50",
    attrs: {
      "variant": "flat-danger"
    },
    on: {
      "click": function click() {
        _vm.isOpenExpiredPackage = false;
      }
    }
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": "XIcon",
      "size": "20"
    }
  })], 1)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }