var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.lazy ? 'BImgLazy' : 'BImg', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.html.v-primary.top.window",
      value: !_vm.hideTooltip ? _vm.titleTooltip : '',
      expression: "!hideTooltip ? titleTooltip : ''",
      modifiers: {
        "hover": true,
        "html": true,
        "v-primary": true,
        "top": true,
        "window": true
      }
    }],
    tag: "components",
    class: "px-0 ".concat(_vm.customClass),
    style: "".concat(!Number(_vm.customWidth) ? "max-height: ".concat(_vm.customHeight, "px") : '', " ").concat(_vm.customStyle),
    attrs: {
      "width": Number(_vm.customWidth),
      "src": _vm.src,
      "rounded": _vm.rounded,
      "right": _vm.right,
      "left": _vm.left,
      "offset": "",
      "alt": _vm.alt || _vm.airline
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }