<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <!-- ANCHOR: Toggle button -->
    <div
      class="navbar-header expanded mb-0"
      style="height: 8rem;"
    >
      <div class="position-absolute position-top-0 position-right-0">
        <b-link class="nav-link modern-nav-toggle">
          <feather-icon
            icon="XIcon"
            size="20"
            class="d-block d-xl-none"
            @click="toggleVerticalMenuActive"
          />
          <!-- :icon="collapseTogglerIconFeather" -->
          <feather-icon
            icon="MenuIcon"
            size="20"
            style="color: #2B3378; stroke-width: 2.5;"
            class="d-none d-xl-block collapse-toggle-icon"
            @click="toggleCollapsed"
          />
        </b-link>
      </div>
      <slot
        name="header"
        :toggle-vertical-menu-active="toggleVerticalMenuActive"
        :toggle-collapsed="toggleCollapsed"
        :collapse-toggler-icon="collapseTogglerIcon"
      >
        <ul
          class="nav navbar-nav flex-row h-100"
          :class="{
            'd-flex-center': !(!isMouseHovered && isVerticalMenuCollapsed && !$store.state.verticalMenu.isShowText)
          }"
        >
          <!-- Logo & Text -->
          <li class="nav-item m-0 d-flex align-items-center">
            <b-link
              class="navbar-brand"
              to="/"
            >
              <div class="position-relative text-title text-uppercase fw-700">
                <span
                  v-if="!isMouseHovered && isVerticalMenuCollapsed && !$store.state.verticalMenu.isShowText"
                  class="brand-logo"
                >
                  <b-img
                    :src="appLogoImage"
                    alt="logo"
                    style="transform: translate(-10px);"
                  />
                  <div
                    v-if="getEnv"
                    class="position-absolute text-subtitle"
                    style="bottom: -15px; right: 0px; color: #2B3378"
                  >
                    {{ getEnv }}
                  </div>
                </span>
                <span v-else>
                  <b-img
                    style="max-height: 5.5rem; max-width: 100%;"
                    class="mt-0"
                    :src="appLogoFull"
                    alt="logo"
                  />
                  <div
                    v-if="getEnv"
                    class="position-absolute"
                    style="bottom: -15px; right: -5px; color: #2B3378"
                  >{{ getEnv }}</div>
                </span>
              </div>
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{ 'd-block': shallShadowBottom }"
      class="shadow-bottom"
    />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >
      <vertical-nav-menu-items
        :items="navMenuItems"
        class="navigation navigation-main"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg } from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'

import navMenuItems from '@/navigation/vertical'
import store from '@/store'

import useAppConfig from '@core/app-config/useAppConfig'

import { $themeConfig } from '@themeConfig'

import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    // HieuLM bảo ko dùng cái này, dùng MenuIcon
    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const { appName, appLogoImage: defaultLogoImage, appLogoFull: defaultLogo } = $themeConfig.app
    const appLogoImage = computed(() => store.getters['app/getFavicon'] || store.getters['app/getLogo'] || defaultLogoImage)
    const appLogoFull = computed(() => store.getters['app/getLogo'] || defaultLogo)

    const getEnv = process.env.VUE_APP_ENV === 'development' ? 'dev' : process.env.VUE_APP_ENV === 'staging' ? 'stg' : ''
    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
      appLogoFull,
      getEnv,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
