<template>
  <b-overlay
    :show="loading"
    rounded="sm"
    no-fade
    variant="transparent"
    :spinner-variant="spinnerVariant"
    :opacity="0.9"
  >
    <slot />
  </b-overlay>
</template>

<script>
import { BOverlay } from 'bootstrap-vue'

export default {
  components: {
    BOverlay,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
    spinnerVariant: {
      type: String,
      default: 'dark',
    },
  },
}
</script>
