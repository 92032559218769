var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "app-content content",
    class: [{
      'show-overlay': _vm.$store.state.app.shallShowOverlay
    }, _vm.$route.meta.contentClass]
  }, [_c('div', {
    staticClass: "content-overlay"
  }), _c('transition', {
    attrs: {
      "name": _vm.routerTransition,
      "mode": "out-in"
    }
  }, [_c('div', {
    staticClass: "content-wrapper clearfix",
    class: _vm.contentWidth === 'boxed' ? 'container p-0' : null
  }, [_vm._t("breadcrumb", [_c('app-breadcrumb')]), _c('div', {
    staticClass: "content-detached content-right"
  }, [_c('div', {
    staticClass: "content-wrapper"
  }, [_c('div', {
    staticClass: "content-body"
  }, [_vm._t("default")], 2)])]), _c('portal-target', {
    attrs: {
      "name": "content-renderer-sidebar-detached-left",
      "slim": ""
    }
  })], 2)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }