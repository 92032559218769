<template>
  <!-- paging -->
  <b-container
    fluid
    class="bg-white py-1"
  >
    <b-row>
      <b-col
        cols="12"
        md="2"
        class="d-flex align-items-center justify-content-center"
      >
        <span class="text-muted">
          {{ dataMeta.from }} {{ $t('paginationText.to') }} {{ dataMeta.to }} {{ $t('paginationText.outOf') }}
          {{ dataMeta.of }} {{ $t('paginationText.items') }}
        </span>
      </b-col>
      <b-col
        cols="12"
        md="8"
        class="d-flex align-items-center justify-content-center"
      >
        <b-pagination
          v-model="localPage"
          :total-rows="total"
          :per-page="localSize"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-col>
      <b-col
        cols="12"
        md="2"
      >
        <v-select
          v-model="localSize"
          :options="sizePerPageLgOptions"
          :clearable="false"
          class="per-page-selector"
        >
          <template #option="data">
            <span>
              {{ data.label }}
            </span>
          </template>

          <template #selected-option="data">
            <span>
              {{ data.label }}
            </span>
          </template>

          <template #no-options>
            {{ $t('noOptions') }}
          </template>
        </v-select>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  computed, ref,
  toRefs,
  watch,
} from '@vue/composition-api'
import {
  BCol, BContainer, BPagination, BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import { sizePerPageLgOptions } from '@/constants/selectOptions'

import icons from '@icons'

export default {
  name: 'IAmPaging',
  components: {
    BPagination,
    BCol,
    BRow,
    BContainer,
    vSelect,
  },
  props: {
    page: {
      type: Number,
      default: 10,
    },
    size: {
      type: Number,
      default: 10,
    },
    total: {
      type: Number,
      default: 10,
    },
    refDataTable: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const {
      page, size, total, refDataTable,
    } = toRefs(props)

    // Tạo localPage và localSize dựa trên prop
    const localPage = ref(page.value)
    const localSize = ref(size.value)

    // Theo dõi thay đổi của prop page và size để cập nhật localPage và localSize
    watch(page, newValue => {
      localPage.value = newValue
    })

    watch(size, newValue => {
      localSize.value = newValue
    })

    watch(localPage, newValue => {
      emit('set-page', newValue)
    })

    watch(localSize, newValue => {
      emit('set-size', newValue)
    })

    const dataMeta = computed(() => {
      const localItemsCount = refDataTable.value
        ? refDataTable.value?.localItems?.length
        : 0
      return {
        from: localSize.value * (localPage.value - 1) + (localItemsCount ? 1 : 0) || 0,
        to: localSize.value * (localPage.value - 1) + localItemsCount,
        of: total.value,
      }
    })

    return {
      icons,
      dataMeta,
      localPage, // Trả về localPage để sử dụng trong v-model của b-pagination
      localSize, // Trả về localSize để sử dụng trong v-select
      sizePerPageLgOptions,
    }
  },
}
</script>
