<template>
  <div id="div-value-money">
    <cleave
      id="value-money"
      v-model="valueMoneyTemp"
      :class="`${disableClass ? '' : 'form-control font-medium-1 font-weight-bolder'}
        ${valueMoneyTemp && typeof Number(valueMoneyTemp) === 'number' ? 'text-right' : '' }
        ${customClass} ${state===false ? 'border-danger' : ''}`"
      :raw="true"
      :options="cleaveOptions"
      :placeholder="placeholder || $t('inputMoneyPlaceholder')"
      :disabled="disabled"
      @input="inputHandle"
      @blur="onBlur"
    />
  </div>
</template>

<script>
import Cleave from 'vue-cleave-component'
import { ref, watch } from '@vue/composition-api'

export default {
  components: {
    Cleave,
  },
  props: {
    valueMoney: {
      type: [Number, String, null],
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    disableClass: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    state: {
      type: [Boolean, null],
      default: null,
    },
    canInputNegative: { // NOTE Nhập số âm hay ko
      type: Boolean,
      default: false,
    },
    onBlur: {
      type: Function,
      default: () => {},
    },
    numeralDecimalScale: {
      type: [Number, String],
      default: 0,
    },
  },
  setup(props, { emit }) {
    const cleaveOptions = {
      numeral: true,
      numeralThousandsGroupStyle: 'thousand',
      signBeforePrefix: props.canInputNegative,
      numeralPositiveOnly: !props.canInputNegative,
      numeralDecimalScale: props.numeralDecimalScale,
    }
    const valueMoneyTemp = ref(props.valueMoney)
    watch(() => props.valueMoney, () => {
      if (Number(valueMoneyTemp.value) !== Number(props.valueMoney) || valueMoneyTemp.value === null) valueMoneyTemp.value = props.valueMoney
    })

    function inputHandle(val) {
      if (Number(val) < 0 && !props.canInputNegative) {
        val = Math.abs(Number(val))
      }
      const value = val || val === 0 ? (Number.isNaN(Number(val)) ? val : Number(val)) : null
      emit('update:value-money', value)
      emit('input', value)
    }

    return {
      cleaveOptions, valueMoneyTemp, inputHandle,
    }
  },
}
</script>
