import { ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'

import store from '@/store'
import { getUserData } from '@/api/auth/utils'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useReservationHandle() {
  // Use toast
  const toast = useToast()

  // Loading
  const loading = ref(null)

  const notificationData = ref(null)

  const resendStop = id => {
    store
      .dispatch('app-notification/resendStop', id)
      .then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Resend stop successfully',
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'resend stop unsuccess',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const resendRestart = id => {
    store
      .dispatch('app-notification/resendRestart', id)
      .then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Resend restart successfully',
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
      })
      .catch(err => {
        toast({
          component: ToastificationContent,
          props: {
            title: err.response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const getNotiById = id => new Promise((resole, reject) => {
    store
      .dispatch('app-notification/getNotiById', id)
      .then(res => resole(res.data))
      .catch(err => {
        reject(err)
        toast({
          component: ToastificationContent,
          props: {
            title: 'fetching error',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  })

  const readNoti = id => new Promise((resole, reject) => {
    store
      .dispatch('app-notification/readNoti', id)
      .then(res => resole(res))
      .catch(err => {
        reject(err)
        toast({
          component: ToastificationContent,
          props: {
            title: 'fetching error',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  })

  const readAllNoti = () => new Promise((resole, reject) => {
    store
      .dispatch('app-notification/readAllNoti', {
        recipientRefType: 'EMPLOYEE',
        recipientRefId: getUserData().employeeData.id.toString(),
      })
      .then(res => resole(res))
      .catch(err => {
        reject(err)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Lỗi làm mới dữ liệu!',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
      })
  })

  const unReadCountNoti = () => new Promise((resole, reject) => {
    store
      .dispatch('app-notification/unReadCountNoti', {
        recipientRefType: 'EMPLOYEE',
        recipientRefId: getUserData().employeeData.id,
      })
      .then(res => resole(res.data))
      .catch(err => {
        reject(err)
        toast({
          component: ToastificationContent,
          props: {
            title: 'fetching error',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  })

  const perfectScrollbarSettings = {
    maxScrollbarLength: 60,
    wheelPropagation: false,
  }

  return {
    loading,
    notificationData,
    perfectScrollbarSettings,

    resendStop,
    resendRestart,
    getNotiById,
    readNoti,
    readAllNoti,
    unReadCountNoti,
  }
}
