<template>
  <li
    v-if="visibleForFloor(item) && visibleForRole(item) && !((item.hideForProduction && isProduction) || item?.hideAll)"
    class="nav-item"
    :class="{
      'active': isActive,
      'disabled': item.disabled || !canViewVerticalNavMenuLink(item)
    }"
  >
    <b-link
      v-bind="linkProps"
      class="d-flex align-items-center pr-0"
      @click="e => handleClickName(e, linkProps)"
    >
      <img
        v-if="item.image"
        :src="item.image"
        height="22"
        class="mr-50"
      >
      <IAmIcon
        v-else-if="item?.customIcon"
        :icon="item?.customIcon"
        size="20"
        class="mr-50"
      />
      <feather-icon
        v-else
        :icon="item.icon || 'CircleIcon'"
        class="mr-50"
      />
      <div class="flex-1 d-flex-between gap-2">
        <span
          v-if="!isVerticalMenuCollapsed || isMouseHovered || $store.state.verticalMenu.isShowText"
          class="menu-title text-truncate"
        >
          {{ t(item.title) }} {{ getRouteDescription(item) === 'F1' ? mamaShortName : getRouteDescription(item) }}
        </span>
        <b-badge
          v-if="(item.tag || item.hideForProduction) && (!isVerticalMenuCollapsed || isMouseHovered)"
          pill
          :variant="item.tagVariant || (item.hideForProduction ? 'light-danger' : '') || 'primary'"
        >
          {{ item.tag || (item.hideForProduction ? 'DEV' : '') }}
        </b-badge>
      </div>
    </b-link>
  </li>
</template>

<script>
import { BLink, BBadge } from 'bootstrap-vue'
import { computed } from '@vue/composition-api'

import store from '@/store'
import env from '@/libs/env'
import { getPortalToken } from '@/views/invoices/useInvoiceHandle'

import { useUtils as useAclUtils } from '@core/libs/acl'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'

export default {
  components: {
    BLink,
    BBadge,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {
      isActive, linkProps, updateIsActive,
      isMouseHovered,
      isVerticalMenuCollapsed,
    } = useVerticalNavMenuLink(props.item)
    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuLink } = useAclUtils()

    const meData = computed(() => store.getters['userStore/getMeData'])

    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
    const isRoleF2 = computed(() => store.getters['userStore/getRoleF2'])
    const isRoleF3 = computed(() => store.getters['userStore/getRoleCTV'])

    const myFloor = computed(() => isRoleF3.value ? 'F3' : isRoleF2.value ? 'F2' : 'F1')
    const childFloor = computed(() => isRoleF1.value ? 'F2' : isRoleF2.value ? 'F3' : '')
    const parentFloor = computed(() => isRoleF3.value ? 'F2' : isRoleF2.value ? 'F1' : '')

    const agencyData = computed(() => store.getters['userStore/getAgencyData'])
    const mamaShortName = env.mamaShortName
    const visibleForRole = route => {
      if (route.visibleRoles) {
        return route.visibleRoles.includes(meData.value?.type)
      }
      return true
    }

    const visibleForFloor = route => {
      if (route.visibleFloors) {
        return route.visibleFloors.includes(myFloor.value)
      }

      if (route.route === 'apps-saleReportRefundTicket') {
        const canIssueVJ = agencyData?.value?.issueLimit?.find(item => item.airlineSource === 'VJ')?.allowance
        return canIssueVJ
      }
      return true
    }

    const getRouteDescription = route => {
      if (route.route === 'apps-parent-workSchedules-page') {
        return isRoleF3.value ? '' : parentFloor.value
      }
      if (route.route === 'apps-workSchedules-page') {
        return isRoleF3.value ? '' : myFloor.value
      }
      if (route.route === 'apps-agencies-list') {
        return isRoleF3.value ? '' : childFloor.value
      }
      if (route.route === 'apps-configsFee') {
        return childFloor.value
      }
      return ''
    }

    async function handleClickName(event, linkProps) {
      if (linkProps?.href && env?.urlInvoicePortal && linkProps?.href?.includes(env?.urlInvoicePortal)) {
        event.preventDefault()
        const token = await getPortalToken()
        if (token) {
          const link = `${linkProps.href}?sessionToken=${token}`
          window.open(link, linkProps.target)
        }
      }
    }
    return {
      myFloor,
      isActive,
      linkProps,
      updateIsActive,
      isProduction: env.isProduction,
      visibleForRole,
      visibleForFloor,
      getRouteDescription,
      isMouseHovered,
      isVerticalMenuCollapsed,
      mamaShortName,
      handleClickName,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,
    }
  },

}
</script>
