<template>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language mr-50"
    right
    no-caret
  >
    <template #button-content>
      <div class="d-flex align-items-center">
        <b-img
          :src="currentLocale.img"
          class="rounded-circle"
          height="30"
          width="30"
          :alt="currentLocale.locale"
        />
        <!-- <span class="mr-50">{{ $t('language') }}:</span>
        <span class="font-weight-bolder">{{ $t(currentLocale.name) }}</span> -->
      </div>
    </template>
    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      @click="handleClickLocale(localeObj.locale)"
    >
      <b-img
        class="mr-1"
        rounded
        height="30"
        width="40"
        :src="localeObj.img"
        :alt="localeObj.locale"
      />
      <span class="text-info">{{ $t(localeObj.name) }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from 'bootstrap-vue'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      const localeSelected = this.locales.find(l => l.locale === this.$i18n.locale)
      localStorage.setItem('Locale', localeSelected.locale)
      return localeSelected
    },
  },
  methods: {
    handleClickLocale(locale) {
      this.$bvModal.show('modal-api-loading')
      const timeoutId = setTimeout(() => {
        this.$i18n.locale = locale
        this.$bvModal.hide('modal-api-loading')
        clearTimeout(timeoutId)
      }, 200)
    },
  },
  setup() {
    const locales = [
      {
        locale: 'en',
        img: require('@/assets/images/flags/en.png'),
        name: 'english',
      },
      {
        locale: 'vi',
        img: require('@/assets/images/flags/vn.png'),
        name: 'vietnam',
      },
      // {
      //   locale: 'fr',
      //   img: require('@/assets/images/flags/fr.png'),
      //   name: 'French',
      // },
      // {
      //   locale: 'de',
      //   img: require('@/assets/images/flags/de.png'),
      //   name: 'German',
      // },
      // {
      //   locale: 'pt',
      //   img: require('@/assets/images/flags/pt.png'),
      //   name: 'Portuguese',
      // },
    ]

    return {
      locales,
    }
  },
}
</script>

<style>

</style>
