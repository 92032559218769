var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.employeeData ? _c('b-nav-item-dropdown', {
    staticClass: "dropdown-user",
    attrs: {
      "id": "dropdown-user",
      "right": "",
      "toggle-class": "d-flex-center justify-content-start gap-1 dropdown-user-link"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-none d-sm-flex user-nav"
        }, [_c('p', {
          staticClass: "user-name font-weight-bolder mb-0",
          class: _vm.isMobileView ? 'text-truncate' : ''
        }, [_vm._v(" " + _vm._s("".concat(['xs'].includes(_vm.appBreakPoint) ? '' : _vm.employeeData.lastName, " ").concat(_vm.employeeData.firstName)) + " ")]), _c('span', {
          staticClass: "user-status",
          staticStyle: {
            "color": "#ff9f43 !important"
          }
        }, [_vm._v(_vm._s(_vm.employeeData.type))])]), _c('b-avatar', {
          staticClass: "badge-minimal shadow",
          attrs: {
            "size": "32",
            "src": _vm.employeeData.avatar,
            "variant": "light-".concat(_vm.resolveEmployeeTypeVariant(_vm.employeeData.type)),
            "text": _vm.avatarText("".concat(_vm.employeeData.lastName, " ").concat(_vm.employeeData.firstName)),
            "badge": "",
            "badge-variant": "success"
          }
        })];
      },
      proxy: true
    }], null, false, 78835096)
  }, [_c('div', {
    staticClass: "d-flex flex-column align-items-center d-sm-none user-nav border-bottom pb-25"
  }, [_c('p', {
    staticClass: "user-name font-weight-bolder mb-0",
    class: _vm.isMobileView ? 'text-truncate' : ''
  }, [_vm._v(" " + _vm._s("".concat(['xs'].includes(_vm.appBreakPoint) ? '' : _vm.employeeData.lastName, " ").concat(_vm.employeeData.firstName)) + " ")]), _c('span', {
    staticClass: "user-status",
    staticStyle: {
      "color": "#ff9f43 !important"
    }
  }, [_vm._v(_vm._s(_vm.employeeData.type))])]), _c('b-dropdown-item', {
    attrs: {
      "to": {
        name: 'account-settings'
      },
      "link-class": "d-flex-center justify-content-start gap-1"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "16",
      "icon": "SettingsIcon"
    }
  }), _c('span', [_vm._v(_vm._s(_vm.$t('account')))])], 1), _c('b-dropdown-item', {
    attrs: {
      "to": {
        name: 'account-settings',
        hash: '#change-password'
      },
      "link-class": "d-flex-center justify-content-start gap-1"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "LockIcon",
      "size": "18"
    }
  }), _c('span', [_vm._v(_vm._s(_vm.$t('changePassword')))])], 1), _c('b-dropdown-item', {
    attrs: {
      "to": {
        name: 'account-settings',
        hash: '#change-payment-password'
      },
      "link-class": "d-flex-center justify-content-start gap-1"
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "keyOutline",
      "size": "18"
    }
  }), _c('span', [_vm._v(_vm._s(_vm.$t('myAccount.paymentPassword.title')))])], 1), _c('b-dropdown-item', {
    attrs: {
      "to": {
        name: 'account-settings',
        hash: '#mfa'
      },
      "link-class": "d-flex-center justify-content-start gap-1"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "KeyIcon",
      "size": "18"
    }
  }), _c('span', [_vm._v(_vm._s(_vm.$t('myAccount.mfa.title')))])], 1), _c('b-dropdown-item', {
    attrs: {
      "link-class": "d-flex-center justify-content-start gap-1"
    },
    on: {
      "click": _vm.showModalConfirmLogout
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "16",
      "icon": "LogOutIcon"
    }
  }), _c('span', [_vm._v(_vm._s(_vm.$t('logout')))])], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }