var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-nav-item-dropdown', {
    staticClass: "dropdown-language mr-50",
    attrs: {
      "id": "dropdown-grouped",
      "variant": "link",
      "right": "",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('b-img', {
          staticClass: "rounded-circle",
          attrs: {
            "src": _vm.currentLocale.img,
            "height": "30",
            "width": "30",
            "alt": _vm.currentLocale.locale
          }
        })], 1)];
      },
      proxy: true
    }])
  }, _vm._l(_vm.locales, function (localeObj) {
    return _c('b-dropdown-item', {
      key: localeObj.locale,
      on: {
        "click": function click($event) {
          return _vm.handleClickLocale(localeObj.locale);
        }
      }
    }, [_c('b-img', {
      staticClass: "mr-1",
      attrs: {
        "rounded": "",
        "height": "30",
        "width": "40",
        "src": localeObj.img,
        "alt": localeObj.locale
      }
    }), _c('span', {
      staticClass: "text-info"
    }, [_vm._v(_vm._s(_vm.$t(localeObj.name)))])], 1);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }