export const EMPLOYEE_COL_IMPORT = {
  type: 4,
  gender: 5,
  firstName: 3,
  lastName: 2,
  username: 6,
  emailAddress: 7,
  phoneNumber: 8,
  bookerType: 9,
  no: 1,
}

export const INVOICE_TICKET_COLUMNS_IMPORT = {
  no: 1,
  bookingCode: 2,
  ticketNumber: 3,
  customerName: 4,
  flightTrip: 5,
  flightDate: 6,
  type: 7,
  unitPrice: 8,
  valueAddedTax: 9,
  collectionFee: 10,
}

export default null
