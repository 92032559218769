var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "div-value-money"
    }
  }, [_c('cleave', {
    class: "".concat(_vm.disableClass ? '' : 'form-control font-medium-1 font-weight-bolder', "\n      ").concat(_vm.valueMoneyTemp && typeof Number(_vm.valueMoneyTemp) === 'number' ? 'text-right' : '', "\n      ").concat(_vm.customClass, " ").concat(_vm.state === false ? 'border-danger' : ''),
    attrs: {
      "id": "value-money",
      "raw": true,
      "options": _vm.cleaveOptions,
      "placeholder": _vm.placeholder || _vm.$t('inputMoneyPlaceholder'),
      "disabled": _vm.disabled
    },
    on: {
      "input": _vm.inputHandle,
      "blur": _vm.onBlur
    },
    model: {
      value: _vm.valueMoneyTemp,
      callback: function callback($$v) {
        _vm.valueMoneyTemp = $$v;
      },
      expression: "valueMoneyTemp"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }