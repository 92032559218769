<template lang="">
  <b-form-checkbox
    :checked="checked"
    :class="customClass"
    :name="name"
    switch
    inline
    :disabled="disabled"
    @click.native.prevent="changeActive"
  />
</template>
<script>
import { BFormCheckbox } from 'bootstrap-vue'

export default {
  components: {
    BFormCheckbox,
  },
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: 'check-button',
    },
    customClass: {
      type: String,
      default: '',
    },
    confirm: {
      type: Boolean,
      default: false,
    },
    reUpdate: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    function changeActive() {
      if (props.disabled) return

      if (props.confirm) {
        this.$bvModal
          .msgBoxConfirm(this.$t('modal.changeStatus'), {
            title: this.$t('modal.confirm'),
            size: 'sm',
            okVariant: 'info',
            okTitle: this.$t('modal.yes'),
            cancelTitle: this.$t('modal.no'),
            cancelVariant: 'outline-danger',
            hideHeaderClose: true,
            centered: true,
          })
          .then(yes => {
            if (yes) {
              emit('input')
              if (props.reUpdate) { emit('update:checked', !props.checked) }
            }
          })
      } else {
        emit('input')
        if (props.reUpdate) { emit('update:checked', !props.checked) }
      }
    }
    return {
      changeActive,
    }
  },

}
</script>
