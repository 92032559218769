var render = function () {
  var _vm$segment;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('IAmOverlay', {
    attrs: {
      "loading": _vm.isEmpty(_vm.segment) || _vm.isEmpty(_vm.trip),
      "spinner-variant": 'info'
    }
  }, [_c('div', {
    staticClass: "d-flex my-50 my-md-75"
  }, [_c('AirportDetail', {
    attrs: {
      "segment": _vm.segment,
      "airport": _vm.segment.departure,
      "store-name": _vm.storeName
    }
  }), _c('div', {
    staticClass: "align-self-center px-50 px-md-1"
  }, [_c('div', {
    staticClass: "d-flex flex-column align-items-center"
  }, [(_vm$segment = _vm.segment) !== null && _vm$segment !== void 0 && _vm$segment.duration ? _c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v(" " + _vm._s(_vm.toHoursAndMinutes(_vm.segment.duration)) + " ")]) : _vm._e(), _c('div', {
    staticClass: "text-nowrap"
  }, [_c('b-img', {
    attrs: {
      "blank-color": "#ccc",
      "src": require('@/assets/images/icons/dot_dash.svg'),
      "width": "120"
    }
  }), _c('b-img', {
    attrs: {
      "blank-color": "#ccc",
      "src": require('@/assets/images/icons/secondary_airline.svg'),
      "width": "20"
    }
  })], 1)]), _c('div', {
    staticClass: "rounded py-50 px-25 px-lg-3",
    staticStyle: {
      "background-color": "#FFF7EA"
    }
  }, [_c('div', {
    staticClass: "d-flex-center flex-wrap flex-md-nowrap"
  }, [_c('IAmLogoAirline', {
    attrs: {
      "airline": _vm.operating === 'BL' ? _vm.operating : null || _vm.airline,
      "size": "sm"
    }
  }), _c('span', {
    staticClass: "text-airline text-nowrap text-center ml-25 font-weight-bolder",
    class: _vm.isMobileView ? 'font-small-4' : 'font-medium-5'
  }, [_vm._v(" " + _vm._s(_vm.getAirlineNameByCode(_vm.airline)) + " ")])], 1), _c('div', {
    staticClass: "text-nowrap text-center mt-md-25 font-weight-bolder"
  }, [_c('span', {
    staticClass: "text-airline fw-700"
  }, [_vm._v(" " + _vm._s(_vm.resolveAirlineFlightNumber(_vm.airline, _vm.flightNumber)) + " ")]), (_vm.airline || _vm.flightNumber) && _vm.segment.airCraft ? _c('span', {
    staticClass: "mx-25"
  }, [_vm._v(" | ")]) : _vm._e(), _c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v(" " + _vm._s(_vm.getAirCraftByIata(_vm.segment.airCraft) || _vm.segment.airCraft) + " ")]), _vm.segment.operating ? _c('p', {
    staticClass: "mb-0 d-flex-center flex-wrap flex-sm-nowrap"
  }, [_c('span', {
    staticClass: "mr-50"
  }, [_vm._v(_vm._s(_vm.$t('flight.sourceBy')))]), _c('span', {
    staticClass: "text-airline fw-700"
  }, [_c('IAmLogoAirline', {
    attrs: {
      "airline": _vm.operating.length === 2 ? _vm.operating : _vm.airline,
      "size": "sm",
      "rounded": ""
    }
  }), _vm._v(" " + _vm._s(_vm.getAirlineNameByCode(_vm.segment.operating)) + " ")], 1)]) : _vm._e()]), _c('div', {
    staticClass: "d-flex-center gap-1"
  }, [_c('span', {
    staticClass: "text-airline fw-700"
  }, [_vm._v(_vm._s(_vm.cabinName))]), _c('span', {
    staticClass: "text-dark"
  }, [_vm.getShowBookingClass ? _c('span', [_vm._v(" ( " + _vm._s(_vm.bookingClass) + " "), _c('small', {
    staticClass: "h6",
    staticStyle: {
      "color": "#E2365D !important"
    }
  }, [_vm._v(" " + _vm._s(_vm.fareBasisCode) + " ")]), _vm._v(" ) ")]) : _vm._e()])])]), ['1S', 'VU'].includes(_vm.trip.source) ? _c('div', {
    staticClass: "text-center mt-25 mt-lg-50"
  }, [_c('b-button', {
    attrs: {
      "id": "btn-check-info-".concat(_vm.trip.id),
      "variant": "flat-warning rounded-lg",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.handleCheckInfoSegment(_vm.segment, {
          name: "".concat(_vm.trip.id, "-").concat(_vm.indexSegment + 1)
        });
      }
    }
  }, [_c('span', {
    staticClass: "border-bottom-warning text-warning"
  }, [_vm._v(" Chi tiết hành trình ")])]), _c('b-modal', {
    attrs: {
      "id": "modal-flightv2-info-trip-".concat(_vm.trip.id, "-").concat(_vm.indexSegment + 1),
      "title": "Thông tin hành trình",
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "centered": "",
      "no-close-on-backdrop": "",
      "size": "lg"
    },
    on: {
      "hide": _vm.handleHideModalInfoTrip
    }
  }, [_c('InfoTripCard', {
    attrs: {
      "data-trip": _vm.segmentInfo
    }
  })], 1)], 1) : _vm._e()]), _c('AirportDetail', {
    attrs: {
      "segment": _vm.segment,
      "airport": _vm.segment.arrival,
      "store-name": _vm.storeName
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }