var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "margin-checkbox-label d-flex-center mt-2"
  }, [_c('b-form-checkbox', {
    attrs: {
      "state": _vm.isConfirmTemp === true ? true : false
    },
    on: {
      "input": _vm.inputHandle
    },
    model: {
      value: _vm.isConfirmTemp,
      callback: function callback($$v) {
        _vm.isConfirmTemp = $$v;
      },
      expression: "isConfirmTemp"
    }
  }, [_c('span', {
    class: _vm.isMobileView ? 'font-medium-1' : 'font-medium-2'
  }, [_vm._v(_vm._s(_vm.$te(_vm.textContent) ? _vm.$t(_vm.textContent) : _vm.textContent))])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }