export const TOPUP_APP_STORE_MODULE_NAME = 'app-topup'

export const MAMA_AGENCY_ID = Number(process.env.VUE_APP_MAMA_AGENCY_ID)

export const AGENCY_STATUS_CAN_TOPUP = 'SIGNED'

export const SENDER_TYPES = {
  AGENCY: 'AGC',
  CUSTOMER: 'CTM',
}

export const MAX_LENGTH = {
  // TODO: RESOLVE 10 follow Backend Validator, 13 follow SRS
  paymentAmount: 10,
  // TODO: RESOLVE payload not include transfer content, 50 follow SRS
  transferContent: 50,
}

export const MIN_AMOUNT_TO_TOPUP = 500000

export const TOPUP_TABLE_COLUMNS = [
  // { label: 'checkbox', key: 'checkbox', sortable: false },
  { label: 'paymentCode', key: 'paymentCode' },
  { label: 'senderInfo', key: 'senderInfo' },
  { label: 'created', key: 'createdAt' },
  { label: 'paymentStatus', key: 'paymentStatus' },
  { label: 'confirmedBy', key: 'confirmedBy' },
  { label: 'paymentInfo', key: 'paymentInfo' },
  { label: 'actions', key: 'actions' },
]
