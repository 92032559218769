<template>
  <div>
    <b-modal
      id="modal-locked-list"
      :title="$t('reservation.lockedList.title')"
      title-class="text-airline font-medium-4 font-weight-bolder"
      centered
      ok-variant="primary"
      no-close-on-backdrop
      no-close-on-esc
      size="lg"
      no-enforce-focus
    >
      <template #modal-footer="{ cancel }">
        <b-button
          size="md"
          variant="secondary"
          class="center rounded-pill"
          @click="cancel()"
        >
          {{ $t('reservation.close') }}
        </b-button>
      </template>

      <b-table
        class="position-relative"
        :items="lockedList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        :empty-text="$t('noMatchingResult')"
        no-border-collapse
        small
      >
        <template
          v-for="column in tableColumns"
          v-slot:[`head(${column})`]
        >
          <span
            :key="column.label"
            class="text-dark text-nowrap"
          >
            {{ $t(`reservation.lockedList.columns.${column}`) }}
          </span>
        </template>

        <template #cell(no)="data">
          <span class="font-weight-bold">{{ data.index + 1 }}</span>
        </template>

        <template #cell(bookingCode)="{item}">
          <b-link
            :to="{ name: 'apps-reservations-modify', params: { id: item.id } }"
            target="_blank"
            class="font-weight-bold"
          >
            {{ item.bookingCode }}
          </b-link>
        </template>
        <template #cell(agPayment)="{item}">
          <span class="font-weight-bold text-uppercase">{{ item.agPayment }}</span>
        </template>
        <template #cell(lockBalanceItem)="{item}">
          <span class="font-weight-bold">{{ formatCurrency(item.lockBalanceItem) }}</span>
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BLink, BModal, BTable,
} from 'bootstrap-vue'

import { formatCurrency } from '@core/utils/filter'

export default {
  components: {
    BModal,
    BButton,
    BTable,
    BLink,
  },
  props: {
    lockedList: {
      type: Array,
      default: () => [],
      required: true,
    },
  },

  setup() {
    const tableColumns = ['no', 'bookingCode', 'agPayment', 'lockBalanceItem']
    return {
      tableColumns, formatCurrency,
    }
  },

}
</script>
