<template>
  <div class="margin-checkbox-label d-flex-center mt-2">
    <b-form-checkbox
      v-model="isConfirmTemp"
      :state="isConfirmTemp === true ? true : false"
      @input="inputHandle"
    >
      <span :class="isMobileView ? 'font-medium-1' : 'font-medium-2'">{{ $te(textContent) ? $t(textContent) : textContent }}</span>
    </b-form-checkbox>
  </div>
</template>

<script>
import { BFormCheckbox } from 'bootstrap-vue'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BFormCheckbox,
  },
  props: {
    isConfirm: {
      type: Boolean,
      required: true,
      default: false,
    },
    textContent: {
      type: String,
      default: 'reservation.iAmConfirm',
    },
  },
  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    const isConfirmTemp = ref(props.isConfirm)
    function inputHandle() {
      emit('update:is-confirm', isConfirmTemp.value)
    }
    return {
      isConfirmTemp,
      inputHandle,
    }
  },
}
</script>

<style lang="scss" scoped>
.margin-checkbox-label::v-deep {
  .custom-control-label {
    margin-top: 5px !important;
  }
}
</style>
