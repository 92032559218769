<template>
  <IAmOverlay
    :loading="isEmpty(segment) || isEmpty(trip)"
    :spinner-variant="'info'"
  >
    <div class="d-flex my-50 my-md-75">
      <AirportDetail
        :segment="segment"
        :airport="segment.departure"
        :store-name="storeName"
      />
      <!-- ANCHOR - Hãng, hạng vé, flightNumber, airCraft -->
      <div class="align-self-center px-50 px-md-1">
        <div class="d-flex flex-column align-items-center">
          <span
            v-if="segment?.duration"
            class="text-nowrap"
          >
            {{ toHoursAndMinutes(segment.duration) }}
          </span>
          <div class="text-nowrap">
            <b-img
              blank-color="#ccc"
              :src="require('@/assets/images/icons/dot_dash.svg')"
              width="120"
            />
            <b-img
              blank-color="#ccc"
              :src="require('@/assets/images/icons/secondary_airline.svg')"
              width="20"
            />
          </div>
        </div>
        <div
          class="rounded py-50 px-25 px-lg-3"
          style="background-color: #FFF7EA;"
        >
          <div class="d-flex-center flex-wrap flex-md-nowrap">
            <IAmLogoAirline
              :airline="operating === 'BL' ? operating : null || airline"
              size="sm"
            />
            <span
              class="text-airline text-nowrap text-center ml-25 font-weight-bolder"
              :class="isMobileView ? 'font-small-4' : 'font-medium-5'"
            >
              {{ getAirlineNameByCode(airline) }}
            </span>
          </div>
          <div class="text-nowrap text-center mt-md-25 font-weight-bolder">
            <span class="text-airline fw-700">
              {{ resolveAirlineFlightNumber(airline, flightNumber) }}
            </span>
            <span
              v-if="(airline || flightNumber) && segment.airCraft"
              class="mx-25"
            > | </span>
            <span class="text-nowrap">
              {{ getAirCraftByIata(segment.airCraft) || segment.airCraft }}
            </span>
            <p
              v-if="segment.operating"
              class="mb-0 d-flex-center flex-wrap flex-sm-nowrap"
            >
              <span class="mr-50">{{ $t('flight.sourceBy') }}</span>

              <span class="text-airline fw-700">
                <IAmLogoAirline
                  :airline="operating.length === 2 ? operating : airline"
                  size="sm"
                  rounded
                />
                {{ getAirlineNameByCode(segment.operating) }}
              </span>
            </p>
          </div>
          <div class="d-flex-center gap-1">
            <span class="text-airline fw-700">{{ cabinName }}</span>
            <span class="text-dark">
              <span v-if="getShowBookingClass">
                (
                {{ bookingClass }}
                <small
                  style="color: #E2365D !important;"
                  class="h6"
                >
                  {{ fareBasisCode }}
                </small>
                )
              </span>
            </span>
          </div>
        </div>
        <div
          v-if="['1S', 'VU'].includes(trip.source)"
          class="text-center mt-25 mt-lg-50"
        >
          <b-button
            :id="`btn-check-info-${trip.id}`"
            variant="flat-warning rounded-lg"
            size="sm"
            @click="handleCheckInfoSegment(segment, {name: `${trip.id}-${indexSegment + 1}`})"
          >
            <span class="border-bottom-warning text-warning">
              Chi tiết hành trình
            </span>
          </b-button>
          <b-modal
            :id="`modal-flightv2-info-trip-${trip.id}-${indexSegment + 1}`"
            title="Thông tin hành trình"
            title-class="text-airline font-medium-4 font-weight-bolder"
            centered
            no-close-on-backdrop
            size="lg"
            @hide="handleHideModalInfoTrip"
          >
            <InfoTripCard :data-trip="segmentInfo" />
          </b-modal>
        </div>
      </div>

      <AirportDetail
        :segment="segment"
        :airport="segment.arrival"
        :store-name="storeName"
      />
    </div>
  </IAmOverlay>
</template>

<script>
import {
  BButton,
  BImg,
  BModal,
} from 'bootstrap-vue'
import { ref, computed, toRefs } from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'

import store from '@/store'

import { toHoursAndMinutes, checkTransitAlert, resolveAirlineFlightNumber } from '@core/utils/filter'

import useBookingHandle from '@flightv2/useBookingHandle'

import useToast from '@useToast'

export default {
  name: 'FlightDetail',
  components: {
    BButton,
    BImg,
    BModal,
    InfoTripCard: () => import('@flightv2/result/components/InfoTripCard.vue'),
    AirportDetail: () => import('./components/AirportDetailCard.vue'),
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
    IAmLogoAirline: () => import('@/components/IAmLogoAirline.vue'),
  },
  props: {
    trip: {
      type: Object,
      default: () => {},
    },
    segment: {
      type: Object,
      default: () => {},
    },
    indexSegment: {
      type: Number,
      default: 0,
    },
    // Truyền vào để follow case flightLegInfoList
    operating: {
      type: String,
      default: () => '',
    },
    airline: {
      type: String,
      default: () => '',
    },
    flightNumber: {
      type: String,
      default: () => '',
    },
    selectedTrip: {
      type: [Object, null],
      default: () => null,
    },
    storeName: {
      type: String,
      default: () => 'app-flight-v2',
    },
  },
  setup(props) {
    const { toastError } = useToast()
    const {
      getClassBookingInfoTrip,
      fareRulesAirline,
    } = useBookingHandle()

    const {
      trip, segment, indexSegment, selectedTrip, storeName,
    } = toRefs(props)
    const segmentInfo = ref([])

    const getAirportByAirportCode = airportCode => store.getters[`${storeName.value}/getAirportByAirportCode`](airportCode)
    const getAirlineNameByCode = code => store.getters['globalConfig/getAirlineNameByCode'](code)
    const getAirCraftByIata = code => store.getters['globalConfig/getAirCraftByIata'](code)
    const getShowBookingClass = computed(() => ['app-flight-v2'].includes(storeName.value) ? store.getters['app-flight-v2/getShowBookingClass'] : false)

    async function handleCheckInfoSegment(segment, { name }) {
      segmentInfo.value = []
      this.$bvModal.show('modal-api-loading')

      try {
        const payload = {
          classBookingId: segment.bookingClassId,
        }
        const payloadSearch = {
          flights: [
            {
              startPoint: segment.departure.IATACode,
              endPoint: segment.arrival.IATACode,
            },
          ],
        }
        const tripData = {
          segmentIndex: 0,
          legsTrip: 1,
          airline: segment.airline,
        }

        getClassBookingInfoTrip(payload, payloadSearch, tripData)
          .then(res => {
            segmentInfo.value = res
            this.$bvModal.show(`modal-flightv2-info-trip-${name}`)
          })
          .catch(err => {
            toastError({
              title: 'Lỗi lấy thông tin hành trình, vui lòng thao tác lại!',
            })
            console.error({ err })
          })
          .finally(() => {
            this.$bvModal.hide('modal-api-loading')
          })
      } catch (error) {
        console.error({ error })
        toastError({
          title: 'Lỗi lấy thông tin hành trình, vui lòng thao tác lại!',
        })
      } finally {
        this.$bvModal.hide('modal-api-loading')
      }
    }

    function handleHideModalInfoTrip() {
      segmentInfo.value = []
    }

    const cabinName = computed(() => fareRulesAirline(
      segment.value?.operating || trip.value?.airline,
      ['QH', 'VU'].includes(trip.value?.source)
        ? (selectedTrip.value?.fareOptions?.fareType || segment.value?.fareType || trip.value?.fareOptions?.fareType || trip.value?.fareOptions[0]?.fareType)
        : (selectedTrip.value?.fareOptions?.groupClass || segment.value?.groupClass),
    ).cabinName || selectedTrip.value?.fareOptions?.groupClass.split(' | ')[indexSegment.value] || selectedTrip.value?.fareOptions?.groupClass || segment.value?.groupClass)

    const bookingClass = computed(() => selectedTrip.value?.fareOptions?.bookingClass?.split(' | ')[indexSegment.value] || selectedTrip.value?.fareOptions?.bookingClass || selectedTrip.value?.bookingClass || segment.value?.bookingClass)
    const fareBasisCode = computed(() => selectedTrip.value?.fareOptions?.fareBasisCode?.split(' | ')[indexSegment.value] || selectedTrip.value?.fareOptions?.fareBasisCode || selectedTrip.value?.fareBasisCode || segment.value?.fareBasisCode || trip.value?.fareOptions?.fareBasisCode?.split(' | ')[indexSegment.value] || trip.value?.fareOptions[0]?.fareBasisCode?.split(' | ')[indexSegment.value])

    return {
      toHoursAndMinutes,
      checkTransitAlert,
      isEmpty,

      segmentInfo,

      handleCheckInfoSegment,
      handleHideModalInfoTrip,

      getAirportByAirportCode,
      getAirlineNameByCode,
      getAirCraftByIata,
      fareRulesAirline,
      getShowBookingClass,
      cabinName,
      bookingClass,
      fareBasisCode,
      resolveAirlineFlightNumber,
    }
  },
}
</script>
