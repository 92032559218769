var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.$route.meta.breadcrumb || _vm.$route.meta.pageTitle ? _c('b-row', {
    staticClass: "content-header"
  }, [_c('b-col', {
    staticClass: "content-header-left",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-row', {
    staticClass: "breadcrumbs-top"
  }, [_c('b-col', {
    staticClass: "d-flex align-items-start flex-column-reverse flex-row justify-content-start",
    attrs: {
      "cols": "12"
    }
  }, [_vm.$route.meta.pageTitle ? _c('div', {
    staticClass: "content-header-title d-flex align-items-center border-0"
  }, [_c('div', {
    staticClass: "d-flex-center"
  }, [_c('h3', {
    staticClass: "my-auto text-dark text-nowrap text-heading-3"
  }, [_vm._v(" " + _vm._s(_vm.$t(_vm.$route.meta.pageTitle)) + " ")])])]) : _vm._e()])], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }