var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    staticClass: "bg-white py-1",
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "d-flex align-items-center justify-content-center",
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('span', {
    staticClass: "text-muted"
  }, [_vm._v(" " + _vm._s(_vm.dataMeta.from) + " " + _vm._s(_vm.$t('paginationText.to')) + " " + _vm._s(_vm.dataMeta.to) + " " + _vm._s(_vm.$t('paginationText.outOf')) + " " + _vm._s(_vm.dataMeta.of) + " " + _vm._s(_vm.$t('paginationText.items')) + " ")])]), _c('b-col', {
    staticClass: "d-flex align-items-center justify-content-center",
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('b-pagination', {
    staticClass: "mb-0 mt-1 mt-sm-0",
    attrs: {
      "total-rows": _vm.total,
      "per-page": _vm.localSize,
      "first-number": "",
      "last-number": "",
      "prev-class": "prev-item",
      "next-class": "next-item"
    },
    scopedSlots: _vm._u([{
      key: "prev-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronLeftIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }, {
      key: "next-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronRightIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.localPage,
      callback: function callback($$v) {
        _vm.localPage = $$v;
      },
      expression: "localPage"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('v-select', {
    staticClass: "per-page-selector",
    attrs: {
      "options": _vm.sizePerPageLgOptions,
      "clearable": false
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.localSize,
      callback: function callback($$v) {
        _vm.localSize = $$v;
      },
      expression: "localSize"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }