import { apiNoti } from '@/api/'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getNoti(ctx, params) {
      return apiNoti.getNotifications(params)
    },

    getNotiById(ctx, id) {
      return apiNoti.getNotiById(id)
    },

    resendStop(ctx, id) {
      return apiNoti.resendStop(id)
    },
    resendRestart(ctx, id) {
      return apiNoti.resendRestart(id)
    },

    readNoti(ctx, id) {
      return apiNoti.readNoti(id)
    },

    readAllNoti(ctx, payload) {
      return apiNoti.readAllNoti(payload)
    },

    unReadCountNoti(ctx, params) {
      return apiNoti.unReadCountNoti(params)
    },
  },
}
